import  { initializeApp } from 'firebase/app'; //core firebase functionality
import { getFirestore } from "firebase/firestore";
 


const firebaseConfig = {
    apiKey: "AIzaSyAyje3_V9LZ4mvP0Cf7FrLNuzCEqEXmf8U",
    authDomain: "am-links-bb001.firebaseapp.com",
    projectId: "am-links-bb001",
    storageBucket: "am-links-bb001.appspot.com",
    messagingSenderId: "703792977330",
    appId: "1:703792977330:web:da7a55df70594f0a2e40ab"
  };
  //init firebase
  const app = initializeApp(firebaseConfig);

  //init firestore service
  const projectFirestore = getFirestore(app);

  export { projectFirestore };