<template>
  <div v-if="splash" id="splash" class="flex-container">
    <div id="flex-item">
      <img id="logo" alt="AM Logo" src="./assets/am-logo.png" />
    </div>
  </div>
</template>

<script>
import { projectFirestore } from "./firebase/config";
import { collection, getDocs } from "firebase/firestore";

export default {
  name: "App",
  components: {},
  data() {
    return {
      splash: true,
      redirect: false,
      uri: null,
    };
  },
  async mounted() {
    //firestore
    try {
      const res = await getDocs(collection(projectFirestore, "enews"));
      const tempDoc = []
      res.forEach((doc) => {
        tempDoc.push( { id: doc.id, ...doc.data()});
      });
      // console.log(tempDoc);
      this.uri = tempDoc[0].uri
      window.location.href = this.uri;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #252d35;
  margin-top: 60px;
}

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#flex-item {
  text-align: center;
  margin: auto;
}

#splash {
  display: flex;
  justify-content: center;
  align-items: center;
}

#logo {
  width: 200px;
}
</style>
